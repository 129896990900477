/*  
       Below function (recursiveScroll) does not work well on Firefox while 
       page resources are loading, is very janky.
       But scroll-behavior: smooth works well while page resources are loading.
       
       Turned on by default in CSS
   */

if (navigator.userAgent.toLowerCase().indexOf('firefox') <= -1) {
    /*  
        Reasons for not using "scroll-behavior: smooth"

        While page resources are still loading Chrome's scroll-behavior: smooth 
        can be very janky and giltchy. Stopping halfway down page, taking many clicks to activate. 
        It is related to amount of JS being loaded.
        This function helps alleviate Chrome/Chrome based browsers issues.

        Safari does not have scroll-behavior: smooth.
        Adds smooth scrolling to Safari.

        Last Tested: 2021/09/25
        Versions: Firefox - 92, Chrome/Edge - 93, Safari - 14
    */
    document.getElementById("root").style = "scroll-behavior: auto;";

    Array.from(document.getElementsByClassName("spotlight-link"))
        .forEach(
            (button) => button.addEventListener("click",
                (e) => {
                    if (window.matchMedia("(prefers-reduced-motion: reduce)").matches === false) {
                        e.preventDefault();
                        window.history.pushState(null, document.title, "#take-a-tour");
                        document.getElementById("take-a-tour-email").focus({ preventScroll: true });

                        $('html, body').animate({ scrollTop: $("#take-a-tour").offset().top }, 1000);
                    }
                }
            )
        );
}
